import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'client-info',
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
        save: false,
      },
      blocks: [
        {
          data: {
            'pivot.status': {
              label: 'cooperation.status',
              type: 'readonly-content',
              readonly: true,
              itemClass: (formInfo) => {
                const status = formInfo.formData.pivot.status
                // @ts-ignore
                if (status == vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT) return 'red--text'
                return 'primary--text'
              },
              text: (data, formInfo) => {
                return vm.$t(`provider.status.detail.${data}`)
              },
            },
            member_name: {
              label: 'store.data.member',
              type: 'liff-text',
              readonly: true,
            },
            phone: {
              label: 'data.phone',
              type: 'liff-text',
              readonly: true,
            },
            uniform_number: {
              label: 'data.uniform_number',
              type: 'liff-text',
              readonly: true,
            },
            address: {
              label: 'data.deliver-address',
              type: 'liff-text',
              readonly: true,
              text: (data, formInfo) => {
                const { formData } = formInfo
                // @ts-ignore
                return `${formData.city}${formData.district}${formData.address}`
              }
            },
            'pivot.payment_type': {
              label: 'data.payment_type',
              type: 'liff-selection',
              horizon: true,
              hideDetails: true,
              hideLabel: true,
              readonlyPrefix: 'data.payment_type',
              readonly: (formInfo) => {
                //@ts-ignore
                const status = formInfo.formData.pivot.status
                //@ts-ignore
                return status != vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
              },
              options: (formInfo) => {
                //@ts-ignore
                const paymentTypes = vm.paymentTypes
                return paymentTypes.map((type: string) => ({ text: vm.$t(`data.payment_type.${type}`), value: type }))
              },
            },
            vip_id: {
              label: '客戶分級',
              type: 'liff-selection',
              horizon: true,
              hideDetails: true,
              hideLabel: true,
              readonlyPrefix: '客戶分級',
              create: (formInfo) => {
                //@ts-ignore
                const status = formInfo.formData.pivot.status
                //@ts-ignore
                return status == vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
              },
              readonly: (formInfo) => {
                //@ts-ignore
                const status = formInfo.formData.pivot.status
                //@ts-ignore
                return status != vm.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION
              },
              options: (formInfo) => {
                //@ts-ignore
                const vipList = formInfo.formData.vip_list
                return vipList.map((vip: any) => ({ text: vip.name, value: vip.id }))
              },
            },
            'pivot.comment': {
              label: 'store.data.comment',
              type: 'liff-textarea',
              readonly: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            receiving_date: {
              label: '商店可收貨時段',
              type: 'liff-text',
              itemClass: 'text-pre-wrap text-wrap',
              readonly: true,
              create: (formInfo) => {
                const { formData } = formInfo
                // @ts-ignore
                return formData.receiving_date.some(day => day.times.some(time => time.start || time.end))
              },
              text: (data, formInfo) => {
                const { formData } = formInfo
                const dayEnum = [
                  { value: 1, text: '星期一', range: []},
                  { value: 2, text: '星期二', range: []},
                  { value: 3, text: '星期三', range: []},
                  { value: 4, text: '星期四', range: []},
                  { value: 5, text: '星期五', range: []},
                  { value: 6, text: '星期六', range: []},
                  { value: 7, text: '星期日', range: []}
                ]
                const dayData = dayEnum.map((day) => {
                  for (const nowDay of formData.receiving_date) {
                    if (day.value === nowDay.week && nowDay.times && nowDay.times.length) {
                      // @ts-ignore
                      day.range = nowDay.times.filter(time => time.start || time.end)
                    }
                  }
                  return day
                  // @ts-ignore
                })
                const showText = dayData.map(day => {
                  // @ts-ignore
                  return `\n${day.text} ${day.range.map(time => (time.start || '∞') + '~' + (time.end || '∞')).join('\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0')}`
                }).join('\n')
                console.log('receiving_datereceiving_date:', formData.receiving_date)
                return showText
              }
            },
          },
        },

      ],
    }
  }

}

export default new formConfig()
