<template>
  <div>
    <eagle-form :form-key="formKey">
      <template v-slot:footer>
        <liff-member-block
          :members="members"
          role="store"
          :showAction="false"
        />
      </template>
    </eagle-form>
  </div>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './storeInfoReadonly'

export default {
  mixins: [formMixin],
  components: {
    liffMemberBlock: () => import('@/modules/member/components/liffMemberBlock.vue')
  },
  data: () => ({
    formKey: 'store-info-readonly',
    meta: {},
    members: [],
  }),
  mounted() {
    this.getMembers()
  },
  computed: {
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    storeId() {
      return this.$route.params.storeId
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    paymentTypes() {
      const provider = this.$store.getters[`base/layoutProvider`]
      if(!provider) return []
      return provider.payment_type
    },
  },
  methods: {
    async getMembers() {
      this.$store.dispatch('loading/active')
      try {
        let members = await this.$api.collection.storeMemberApi.storeToProviderToMangersIndex(
          this.storeId, 
          this.providerId, 
          {pager: 0}
        )
        this.members = members.map(member => {
          return {
            ...member,
            isMaster: member.IsStoreMaster
          }
        })
      } catch(err) {
        // this.$snotify.error('讀取會員失敗')
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    async afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      console.log('readApi:', target)
      const res1 =  await this.$api.collection.storeApi.readByProviderAndStore(this.storeId, this.providerId)
      const res2 =  await this.$api.collection.vipApi.read(this.providerId)
      const res3 =  await this.$api.collection.providerApi.readStoreVip(this.providerId, { store_id: [this.storeId] })
      const res4 =  await this.$api.collection.receivingDateApi.index(this.storeId)
      const vipId = res3.length > 0 ? res3[0].vip_group_id : 0
      this.$emit('setFormKey', this.formKey)
      return Object.assign(res1, {vip_list: res2}, {vip_id: vipId}, {receiving_date: res4})
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass">

</style>
